export default {
  primary_search: {
    title: 'Pronađi',
    price_min: 'Cena od',
    price_max: 'Cena do',
    area_min: 'Površina od',
    area_max: 'Površina do',
    building_type_placeholder: 'Koji tip zgrade?',
    property_type_placeholder: 'Koji tip nekretine?',
    rent_type: {
      rent: 'Izdavanje',
      sale: 'Prodaja',
    },
    find: 'Pretraži'
  },
  actions: {
    show_favorites: 'Omiljene',
    hide_favorites: 'Rezultati pretrage',
    sort: 'Sortiraj prema',
    sort_types: {
      rating: 'Našoj oceni',
      price: 'Ceni rastuće',
      area: 'Površini rastuće'
    },
    cancel_discarded: 'Vrati poništene rezultate',
    discard: 'Ukloni iz pretrage',
    remove_image: 'Ukloni',
    mark_as_preview: 'Označi kao glavnu'
  },
  confirmations: {
    image_remove: 'Da li ste sigurni da želite da uklonite ovu sliku?'
  },
  alerts: {
    image_remove_error: 'Javio se neki problem i slika nije uklonjena',
    image_preview_error: 'Javio se neki problem i slika nije postavljena kao glavna slika'
  },
  images: {
    preview: 'Glavna slika'
  },
  filters: {
    favorites_title: 'Omiljeni:',
    favorites_shown: 'Ne možete da koristite filtere za omiljene',
    title: 'Suzi pretragu',
    building_type: 'Tip zgrade',
    property_id: 'Pretraži po ID nekretnine',
    property_id_placeholder: 'Unesi ID nekretnine',
    price_min: 'Cena od',
    price_max: 'Cena do',
    area_min: 'Površina od',
    area_max: 'Površina do',
    filter_btn: 'Filtriraj',
    results_count: 'Pronađenih nekretnina:',
    bedrooms: 'Spavaćih soba',
    bedroom_options: {
      '1': '1+',
      '2': '2+',
      '3': '3+',
      '4': '4+',
      '5': '5 ili više'
    },
    bathrooms: 'Kupatila',
    bathroom_options: {
      '1': '1+',
      '2': '2+',
      '3': '3+',
      '4': '4+',
      '5': '5 ili više'
    },
    furnishment: 'Opremljenost',
    furnishment_types: {
      furnished: 'Opremljen',
      unfurnished: 'Prazan',
      partially_furnished: 'Delimično opremljen',
      tba: 'Po dogovoru'
    },
    floor_type: 'Spratnost',
    floor_types: {
      ground: 'Prizemno',
      mezzanine: 'Medjusprat (prvi sprat)',
      middle: 'Srednji spratovi',
      top: 'Potkrovlje'
    },
    elevator: 'Lift',
    garage: 'Garaža',
    parking: 'Parking',
    terrace: 'Terasa',
    yard: 'Dvorište',
    swimming_pool: 'Bazen',
    doorkeeper: 'Portir',
    security: 'Obezbeđenje',
    distribution: 'Distribucija',
    distribution_types: {
      open_plan: 'Otvoren prostor',
      walled: 'Sa više zidova',
      indifferent: 'Neodređeno'
    }
  },
  emails: {
    residential: 'residential@habitat.rs',
    office: 'office@habitat.rs',
    retail: 'retail@habitat.rs',
    industrial: 'industrial@habitat.rs'
  },
  property: {
    property_id: 'ID Nekretnine',
    street_name: 'Ulica',
    url: 'Podeli URL',
    city_areas: {
      banovo_brdo: 'Banovo Brdo',
      center: 'Centar',
      dedinje: 'Dedinje',
      kosutnjak: 'Košutnjak',
      new_belgrade: 'Novi Beograd',
      senjak: 'Senjak',
      vozdovac: 'Voždovac',
      vracar: 'Vračar',
      zemun: 'Zemun',
      zvezdara: 'Zvezdara'
    },
    building_type: 'Tip zgrade',
    building_types: {
      residential: 'Stambena',
      office: 'Komercijalna',
      retail: 'Maloprodaja',
      industrial: 'Industrijska i skladišta',
    },
    property_type: 'Tip objekta',
    property_types: {
      apartment: 'Apartman',
      penthouse: 'Penthouse',
      duplex: 'Dupleks',
      house_single: 'Kuća',
      house_semi_detached: 'Dvojna kuća',
      house_in_row: 'Kuće u redu',
      office_building: 'Poslovna zgrada',
      mixed_use_apartment: 'Prostor za više namena',
      shopping_center: 'Shopping Centar',
      high_street: 'Glavna ulica',
      other: 'Drugo',
      warehouse: 'Skladište',
      industrial_premises: 'Industrijska zona'
    },
    square_area: 'Površina',
    price: 'Cena',
    per_m2: '/ m2',
    floor: 'Sprat',
    floors: 'Broj spratova',
    floor_type: 'Spratnost',
    floor_types: {
      ground: 'Prizemno',
      mezzanine: 'Medjusprat (prvi sprat)',
      middle: 'Srednji spratovi',
      top: 'Potkrovlje'
    },
    storey: 'Unutrašnjih spratova',
    elevator: 'Ima lift',
    garage: 'Ima garažu',
    garage_notes: 'Opis garaže',
    parking: 'Ima parking',
    parking_notes: 'Opis parkinga',
    rooms: 'Broj soba',
    offices: 'Broj kancelarija',
    bedrooms: 'Broj spavaćih soba',
    bathrooms: 'Broj kupatila',
    toilets: 'Broj toaleta',
    bathroom_type: 'Tip kupatila',
    bathroom_types: {
      full: 'Kompletno kupatilo',
      half: 'Polovično kupatilo',
      toilet: 'Toalet',
      laundry: 'Perionica'
    },
    bathroom_notes: 'Opis kupatila',
    furnishment: 'Opremljenost',
    furnishment_types: {
      furnished: 'Opremljen',
      unfurnished: 'Prazan',
      partially_furnished: 'Delimično opremljen'
    },
    style_of_furnish: 'Stil nameštaja',
    furnishment_style: {
      modern: 'Moderan',
      classic: 'Klasični',
      rustic: 'Rustični',
      vintage: 'Vintaž',
      romantic: 'Romantični'
    },
    furnishment_at_rent: 'Opremljenost pri izdavanju',
    furnishment_at_rent_types: {
      furnished: 'Opremljen',
      unfurnished: 'Prazan',
      partially_furnished: 'Delimično opremljen',
      tba: 'Po dogovoru'
    },
    kitchen_type: 'Tip kuhinje',
    kitchen_types: {
      separate: 'Odvojena',
      open: 'Otvorena',
      semi_open: 'Delimično otvorena'
    },
    terrace: 'Ima terasu',
    heating: 'Grejanje',
    heating_notes: 'Opis grejanja',
    heating_types: {
      central: 'Centralno',
      electric: 'Električno',
      gas: 'Gas',
      oil: 'Nafta',
      other: 'Drugo'
    },
    phone_lines: 'Broj telefonskih linija',
    description: 'Opis',
    yard: 'Dvorište',
    yard_notes: 'Opis dvorišta',
    construction_year: 'Godina izgradnje',
    condition: 'Stanje',
    condition_types: {
      new: 'Novo',
      renovated: 'Renovirano',
      partially_renovated: 'Delimično renovirano',
      needs_renovation: 'Potrebno renoviranje',
      under_construction: 'Radovi u toku'
    },
    rating: 'Ocena objekta',
    kitchen_appliances: 'Kuhinjski aparati',
    dishwasher: 'Mašina za sudove',
    washing_machine: 'Mašina za pranje',
    dryer: 'Fen',
    tv_set: 'TV',
    air_conditioning: 'Klima uređaj',
    cable_tv: 'Kablovska TV',
    satellite: 'Satelitska TV',
    internet: 'Internet',
    video_surveillance: 'Video nadzor',
    alarm_system: 'Alarmni sistem',
    swimming_pool: 'Bazen',
    gym: 'Teretana',
    sauna: 'Sauna',
    porter: 'Portir',
    appliance_options: {
      yes: 'Da',
      no: 'Ne'
    },
    boolean_values: {
      true: 'Da',
      false: 'Ne',
      yes: 'Da',
      no: 'Ne'
    },
    impending: 'Po dogovoru',
    adsl: 'ADSL',
    cable: 'Kablovski',
    wifi: 'WiFi',
    features: 'Karakteristike',
    map: 'Mapa',
    overview: 'Ukratko'
  }
}