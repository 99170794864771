import React, {Component} from 'react'

import {I18n} from 'packs/locales';
import {
  getPropertyTypes,
  getBuildingTypes,
  getRoomsTypes,
  getFurnishmentTypes,
  getDistributionTypes,
  getFloorTypes
} from 'packs/utils/types';

class Base extends Component {
  constructor(props) {
    super(props);

    this.I18n = new I18n();

    const filter = props.filter;
    this.state = {filter: filter, properties: []};

    this.propertyTypes = [];
    this.furnishmentTypes = [];
    this.bedroomTypes = [];
    this.bathroomTypes = [];
    this.distributionTypes = [];
    this.floorTypes = [];
    this.rentOptions = [
      { value: 'rent', label: this.I18n.t('primary_search.rent_type.rent') },
      { value: 'sale', label: this.I18n.t('primary_search.rent_type.sale') },
    ];

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    if ($('.checkbox').length > 0) {
      $('.checkbox input').iCheck().on('ifToggled', (e) => {
        this.handleChange(e);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({filter: nextProps.filter, properties: nextProps.properties});
      if (nextProps.properties) {
        this.propertyTypes = getPropertyTypes(this.I18n, nextProps.properties);
        this.furnishmentTypes = getFurnishmentTypes(this.I18n, nextProps.properties);
        this.bedroomTypes = getRoomsTypes(this.I18n, nextProps.properties, 'bedroom');
        this.bathroomTypes = getRoomsTypes(this.I18n, nextProps.properties, 'bathroom');
        this.distributionTypes = getDistributionTypes(this.I18n, nextProps.properties);
        this.floorTypes = getFloorTypes(this.I18n, nextProps.properties);
      }
      if (nextProps.per_building_type) {
        this.buildingOptions = getBuildingTypes(this.I18n, nextProps.per_building_type);
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const filter = this.state.filter;
    filter[name] = value;
    this.setState({
      filter: filter
    }, () => {
      this.props.onChange(this.state.filter)
    });
  }

}
export default Base;