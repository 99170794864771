import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PropertyGallery from "./property-gallery";
import guid from '../utils/guid';

import {I18n} from '../locales/index';
import Icon from "../utils/icon";
import cn from 'classnames'

require('jquery')
require('bootstrap')

class PropertyItem extends Component {
  constructor(props) {
    super(props);

    this.onPropertyClick = this.onPropertyClick.bind(this);
    this.onAverageColor = this.onAverageColor.bind(this);

    this.itemElement = 'random-' + guid();
    this.I18n = new I18n();

    this.state = {
      favorite: this.props.property.isFavorited(),
      idle: false
    };

    this.addToFavorites = this.addToFavorites.bind(this);
    this.removeFromFavorites = this.removeFromFavorites.bind(this);
    this.discard = this.discard.bind(this);
  }

  discard(e) {
    e.preventDefault();

    this.props.onDiscard(this.props.property);
  }

  addToFavorites(e) {
    if (e) e.preventDefault();

    if (this.props.property) {
      this.props.property.addToFavorites();
      this.setState({favorite: true}, () => {
        this.props.onFavoriteToggle(this.props.property.property_id, true)
      });
    }
  }

  removeFromFavorites(e) {
    if (e) e.preventDefault();

    if (this.props.property) {
      this.props.property.removeFromFavorites();
      this.setState({favorite: false}, () => {
        this.props.onFavoriteToggle(this.props.property.property_id, false)
      });
    }
  }

  onPropertyClick(e) {
    if (e) e.preventDefault();

    this.props.onPropertyClick(this.props.property);
  }

  onAverageColor(color) {
    if (color) {
      let colorString = `rgb(${color.r}, ${color.g}, ${color.b})`;
      if (colorString != 'rgb(0, 0, 0)') {
        if (this.itemElement) {
          $(`#${this.itemElement}`).find('.average-color').css({backgroundColor: colorString});
        }
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({idle: true})
    }, 300)

    $(this.discardBtn).tooltip({container: 'body'})
  }

  componentWillUnmount() {
    $(this.discardBtn).tooltip('destroy')
  }

  render() {
    const {property} = this.props;
    return <div className={cn({
      'item': true,
      'move_from_bottom': true,
      'idle': this.state.idle
    })}>

      <div className="inner" id={this.itemElement}>
        <div className="image">
          <div className="price average-color"><span>{property.price}</span></div>
          <PropertyGallery property={property}
                           onAverageColor={this.onAverageColor}
                           size="medium"/>
        </div>
        <div className="favorite">
          {property.favorited ?
            <a href="#"
               className="favorites-btn remove"
               onClick={this.removeFromFavorites}
            ><Icon name="ion-android-favorite"/></a> :
            <a href="#"
               className="favorites-btn add"
               onClick={this.addToFavorites}
            ><Icon name="ion-android-favorite-outline"/></a>}
        </div>
        <div className="discard-property">
          <a href="#"
             ref={el => this.discardBtn = el}
             className="discard-btn"
             title={this.I18n.t('actions.discard')}
             onClick={this.discard}>
            <Icon name="ion-trash-b"/>
          </a>
        </div>
        <a href="#" onClick={this.onPropertyClick}>
          <div className="item-content">
            <header className="average-color">
              <h2>{property.street_name}</h2>
            </header>
            <footer>
              <dl>
                <dt>{this.I18n.t('property.square_area')}</dt>
                {property.square_areas.map((area, index) => (
                  <dd key={index}>{area.area}m<sup>2</sup></dd>
                ))}
                <dt>
                  {property.isOffice() && (this.I18n.t('property.offices'))}
                  {property.isResidential() && this.I18n.t('property.bedrooms')}
                  {property.isIndustrialOrRetails() && this.I18n.t('property.rooms')}
                </dt>
                {property.isResidential() && (<dd>{property.bedrooms}</dd>)}
                {!property.isResidential() && (<dd>{property.rooms}</dd>)}
                <dt>{this.I18n.t('property.bathrooms')}</dt>
                <dd>{property.bathrooms}</dd>
                <dt>{this.I18n.t('property.garage')}</dt>
                <dd>{this.I18n.t(`property.boolean_values.${property.garage}`)}</dd>
              </dl>
            </footer>
          </div>
        </a>
      </div>
    </div>
  }
}

PropertyItem.propTypes = {
  property: PropTypes.object.isRequired,
  onPropertyClick: PropTypes.func.isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired
};

export default PropertyItem;