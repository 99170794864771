import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';

import Select from 'react-select'

class Residential extends Base {
  constructor(props) {
    super(props);

    this.handleBedroomChange = this.handleBedroomChange.bind(this);
    this.handleBathroomChange = this.handleBathroomChange.bind(this);
    this.handleFurnishmentChange = this.handleFurnishmentChange.bind(this);
    this.handleFloorTypeChange = this.handleFloorTypeChange.bind(this);
  }

  handleBedroomChange(bedrooms) {
    const {filter} = this.state;
    filter.bedrooms = bedrooms ? bedrooms.value : null;
    this.setState({filter: filter}, () => {
      this.props.onChange(this.state.filter);
    });
  }

  handleBathroomChange(bathrooms) {
    const {filter} = this.state;
    filter.bathrooms = bathrooms ? bathrooms.value : null;
    this.setState({filter: filter}, () => {
      this.props.onChange(this.state.filter);
    });
  }

  handleFurnishmentChange(furnishment) {
    const {filter} = this.state;
    const furnishment_selected = filter.furnishment ? filter.furnishment.split(',').length : 0;
    filter.furnishment = furnishment.map(pt => pt.value).join(',');
    this.setState({filter: filter}, () => {
      const new_furnishment_selected = filter.furnishment ? filter.furnishment.split(',').length : 0;
      const search_if_removed = furnishment_selected > new_furnishment_selected;
      this.props.onChange(this.state.filter, search_if_removed);
    });
  }

  handleFloorTypeChange(floor_type) {
    const {filter} = this.state;
    const floor_type_selected = filter.floor_type ? filter.floor_type.split(',').length : 0;
    filter.floor_type = floor_type.map(pt => pt.value).join(',');
    this.setState({filter: filter}, () => {
      const new_floor_type_selected = filter.floor_type ? filter.floor_type.split(',').length : 0;
      const search_if_removed = floor_type_selected > new_floor_type_selected;
      this.props.onChange(this.state.filter, search_if_removed);
    });
  }

  render() {
    return <div>
      <div className="form-group">
        <label>{this.I18n.t('filters.bedrooms')}</label>
        <Select
          isClearable
          isSearchable={false}
          placeholder={this.I18n.t('filters.bedrooms')}
          value={this.bedroomTypes.find(o => o.value === parseInt(this.state.filter.bedrooms))}
          options={this.bedroomTypes}
          onChange={this.handleBedroomChange}
        />
      </div>
      <div className="form-group">
        <label>{this.I18n.t('filters.bathrooms')}</label>
        <Select
          isClearable
          isSearchable={false}
          placeholder={this.I18n.t('filters.bathrooms')}
          value={this.bathroomTypes.find(o => o.value === parseInt(this.state.filter.bathrooms))}
          options={this.bathroomTypes}
          onChange={this.handleBathroomChange}
        />
      </div>
      <div className="form-group">
        <label>{this.I18n.t('filters.furnishment')}</label>
        <Select
          isClearable
          isSearchable={false}
          isMulti
          placeholder={this.I18n.t('filters.furnishment')}
          value={this.furnishmentTypes.find(o => o.value === this.state.filter.furnishment)}
          options={this.furnishmentTypes}
          onChange={this.handleFurnishmentChange}
        />
      </div>

      <div className="form-group">
        <label>{this.I18n.t('filters.floor_type')}</label>
        <Select
          isClearable
          isSearchable={false}
          isMulti
          placeholder={this.I18n.t('filters.floor_type')}
          value={this.floorTypes.find(o => o.value === this.state.filter.floor_type)}
          options={this.floorTypes}
          onChange={this.handleFloorTypeChange}
        />
      </div>

      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="elevator" checked={this.state.filter.elevator} onChange={this.handleChange}/>
          {this.I18n.t('filters.elevator')}
          &nbsp;({this.state.properties.filter(p => p.elevator === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="garage" checked={this.state.filter.garage} onChange={this.handleChange}/>
          {this.I18n.t('filters.garage')}
          &nbsp;({this.state.properties.filter(p => p.garage === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="parking" checked={this.state.filter.parking} onChange={this.handleChange}/>
          {this.I18n.t('filters.parking')}
          &nbsp;({this.state.properties.filter(p => p.parking === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="terrace" checked={this.state.filter.terrace} onChange={this.handleChange}/>
          {this.I18n.t('filters.terrace')}
          &nbsp;({this.state.properties.filter(p => p.terrace === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="yard" checked={this.state.filter.yard} onChange={this.handleChange}/>
          {this.I18n.t('filters.yard')}
          &nbsp;({this.state.properties.filter(p => p.yard === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="swimming_pool" checked={this.state.filter.swimming_pool}
                 onChange={this.handleChange}/>
          {this.I18n.t('filters.swimming_pool')}
          &nbsp;({this.state.properties.filter(p => p.swimming_pool === 'yes').length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="doorkeeper" checked={this.state.filter.doorkeeper} onChange={this.handleChange}/>
          {this.I18n.t('filters.doorkeeper')}
          &nbsp;({this.state.properties.filter(p => p.porter === 'yes').length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="security" checked={this.state.filter.security} onChange={this.handleChange}/>
          {this.I18n.t('filters.security')}
          &nbsp;({this.state.properties.filter(p => p.building_security === true).length})
        </label>
      </div>
    </div>
  }
}

Residential.propTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired
};

export default Residential;