import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string';
import 'whatwg-fetch'
import {
  withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/addons/InfoBox';

import mapStyles from './utils/map-style.json';
import getMapBoundingBox from './utils/map-bounding-box';
import replaceState from './utils/replace-state';

import PropertyView from './views/property-item';
import PropertyModel from './models/property';
import PropertyPopup from './views/property-popup';

import Masonry from 'masonry-layout';

import './styles/bootstrap-4-helpers.scss'
import './styles/style.scss'
import './styles/habitat.scss'
import PropertyPage from './views/property-page';

import PrimarySearch from './views/search/primary';
import FiltersAll from './views/search/filters/all';
import Filter from 'packs/models/filter';

import { I18n } from 'packs/locales/';
import ByBuildingType from "./views/search/filters/by-building-type";
import Icon from 'packs/utils/icon';

import Select from 'react-select';
import Cookies from 'js-cookie';

const GettingStartedGoogleMap = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapMounted}
    zoom={props.zoom}
    defaultOptions={{
      styles: mapStyles,
      disableDefaultUI: false,
      scrollwheel: false,
      streetViewControl: true,
      disableDoubleClickZoom: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER
      },
      panControl: false,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    }}
    center={props.center}
  >
    {props.markers.map((marker, index) => (
      <Marker key={`marker-${index}`}
        onClick={() => props.onMarkerClick(marker)}
        {...marker}
      >
        {marker.showInfo && (
          <InfoBox
            options={{
              closeBoxURL: "/img/close-infobox.png",
              disableAutoPan: false,
              pixelOffset: new google.maps.Size(-260, -10),
              zIndex: 'null',
              alignBottom: true,
              boxClass: "infobox",
              enableEventPropagation: true,
              closeBoxMargin: "10px 10px -30px 0px",
              infoBoxClearance: new google.maps.Size(1, 1)
            }}
            onCloseClick={() => props.onMarkerClose(marker)}>
            <PropertyPopup property={marker.property}
              onDiscard={props.onDiscard}
              onFavoriteToggle={props.onFavoriteToggle}
              onPropertyClick={marker.onPropertyClick} />
          </InfoBox>
        )}
      </Marker>
    ))}
  </GoogleMap>
));

class MainSearch extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.updateUrlFromState = this.updateUrlFromState.bind(this);
    this.updateStateFromUrl = this.updateStateFromUrl.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.handleMarkerClose = this.handleMarkerClose.bind(this);

    this.onPropertyClick = this.onPropertyClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.search = this.search.bind(this);
    const filter = new Filter();

    const discarded = Cookies.getJSON('discarded') || [];

    this.state = {
      center: new google.maps.LatLng(props.center.lat, props.center.lng),
      zoom: props.zoom,
      properties: [],
      selectedProperty: null,
      initial_search: true,
      filter: filter,
      per_building_type: props.per_building_type,
      showFavorites: false,
      discarded: discarded,
      page: 1,
      total_pages: 1,
      total_entries: 0
    };

    this.I18n = new I18n();

    this.sortOptions = [
      { value: 'rating', label: this.I18n.t('actions.sort_types.rating') },
      { value: 'price', label: this.I18n.t('actions.sort_types.price') },
      { value: 'area', label: this.I18n.t('actions.sort_types.area') }
    ]

    this.onFavoriteToggle = this.onFavoriteToggle.bind(this);
    this.toggleFavorites = this.toggleFavorites.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);

    this.discardProperty = this.discardProperty.bind(this);
    this.cancelDiscarded = this.cancelDiscarded.bind(this);
  }

  componentDidMount() {
    if (window.location.hash) {
      this.updateStateFromUrl();
    }
  }

  /** DISCARD FUNCTIONS **/
  cancelDiscarded(e) {
    e.preventDefault();

    Cookies.set('discarded', []);
    this.setState({ discarded: [] })
  }

  discardProperty(property) {
    const { discarded } = this.state;
    discarded.push(property.property_id)
    this.setState({ discarded: discarded }, () => {
      Cookies.set('discarded', discarded);
    })
  }

  componentDidUpdate() {
    // if (this.masonry) {
    //   setTimeout(() => {
    //     new Masonry(this.masonry, {
    //       itemSelector: '.item',
    //       gutter: 30
    //     });
    //   }, 300);
    // }
  }

  /** SEARCH **/
  search(e = null, onPageReload = false,page= 1) {
    if (e) e.preventDefault();
    const urlParams = this.composeSearchUrl();
    this.updateUrlFromState();
    console.log(urlParams)
    fetch(`${this.props.search_url}${urlParams}&page=${page}`, {
      credentials: 'same-origin',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }).then((response) => response.json())
      .then((json) => {
        let properties = [];
        json.properties.forEach((property) => {
          properties.push(new PropertyModel(property));
        });
        this.setState({
          properties: page == 1 ? properties :  [...this.state.properties, ...properties],
          per_building_type: json.per_building_type,
          page: json.page,
          total_pages: json.total_pages,
          total_entries: json.total_entries
        }, () => {
          console.log(this.state)
          // on page relaod try to open existing property
          if (onPageReload) {
            if (this.state.filter.property) {
              const property_to_open = properties.filter((p) => {
                return p.property_id === this.state.filter.property;
              })[0];
              if (property_to_open) {
                this.onPropertyClick(property_to_open);
              }
            }
          }
        });
      });
  }

  onFiltersChange(primary_filters, search = false) {
    const { filter } = this.state;
    filter.set(primary_filters);
    this.setState({ filter: filter, initial_search: false }, () => {
      if (search) {
        this.search();
      }
    });
  }

  /** URL HANDLERS **/
  updateUrlFromState() {
    const { filter } = this.state;
    replaceState(filter.serialize());
  }

  updateStateFromUrl() {
    const hash = window.location.hash;
    const params = queryString.parse(hash);

    if (!params) return;
    let state = { initial_search: false };
    const { filter } = this.state;
    filter.deserialize(hash);
    state.filter = filter;
    this.setState(state, () => {
      this.search(null, true);
    });
  }

  composeSearchUrl() {
    var { filter } = this.state;
    return '?' + filter.serialize();
  }

  /** MAP HANDLERS **/
  handleMapMounted(map) {
    this._map = map;
  }

  /** MARKER HANDLERS **/
  handleMarkerClick(targetMarker) {
    this.setState({
      properties: this.state.properties.map(property => {
        if (property.property_id === targetMarker.property_id) {
          property.showInfo = true;
          return property;
        }
        return property;
      }),
    });
  }

  handleMarkerClose(targetMarker) {
    this.setState({
      properties: this.state.properties.map(property => {
        if (property.property_id === targetMarker.property_id) {
          property.showInfo = false;
          return property;
        }
        return property;
      }),
    });
  }

  onPropertyClick(property) {
    const { filter } = this.state;
    filter.property = property ? property.property_id : null;
    this.setState({ selectedProperty: property, filter: filter }, () => {
      this.updateUrlFromState();
    });
  }

  onFavoriteToggle(propertyId, isFavorited) {
    let index = -1;
    let { properties } = this.state;
    let property = properties.filter(p => {
      return p.property_id === propertyId
    })[0];
    if (property) {
      property.favorited = isFavorited;
      if (this.state.showFavorites) {
        const favorites = properties.filter(p => p.favorited)
        this.setState({ properties: favorites });
      } else {
        this.setState({ properties: this.state.properties });
      }
    }
  }

  toggleFavorites(e) {
    if (e) e.preventDefault();

    const showFavorites = !this.state.showFavorites;
    if (showFavorites) {
      // if we should show favorites, then update properties to match only favorites
      const favorites = [];
      this.state.properties.forEach(property => {
        if (property.favorited) {
          favorites.push(property);
        }
      });
      this.setState({ showFavorites: true, properties: favorites });
    } else {
      this.setState({ showFavorites: false });
      this.search();
    }
  }

  handleSortChange(sort) {
    const { filter } = this.state;
    filter.sort = sort.value;
    if (this.state.showFavorites) {
      let properties = this.state.properties;
      properties.sort((a, b) => {
        switch (filter.sort) {
          case 'rating':
            return a.rating < b.rating ? -1 : 1;
          case 'price':
            return a.price_integer < b.price_integer ? -1 : 1;
          case 'area':
            return a.square_area < b.square_area ? -1 : 1;
        }
      })
      this.setState({ filter: filter, properties: properties });
    } else {
      this.setState({ filter: filter });
      this.search();
    }
  }

  /** RENDERERS **/

  render() {

    const properties = this.state.properties.filter(p => {
      return this.state.discarded.indexOf(p.property_id) === -1
    })

    const markers = properties.map(property => {
      return {
        property_id: property.property_id,
        property: property,
        position: new google.maps.LatLng(property.location[0], property.location[1]),
        showInfo: property.showInfo,
        infoContent: property.street_name,
        icon: { url: '/img/markerred.png', scaledSize: new google.maps.Size(28, 33) },
        onPropertyClick: this.onPropertyClick
      }
    });

    const favorites = [];
    properties.forEach(property => {
      if (property.favorited) {
        favorites.push(property);
      }
    });


    return <div>
      {this.state.initial_search ? <PrimarySearch
        onSearch={(filters) => this.onFiltersChange(filters, true)}
        per_building_type={this.state.per_building_type}
      /> : null}
      <PropertyPage
        property={this.state.selectedProperty}
        onPropertyClose={() => this.onPropertyClick(null)}
        composeSearchUrl={this.state.filter.serialize()}
      />

      <div id="google-map-wrapper">
        <GettingStartedGoogleMap
          zoom={this.state.zoom}
          center={this.state.center}
          containerElement={
            <div style={{ height: `100%` }} />
          }
          mapElement={
            <div style={{ height: `500px` }} />
          }
          onMapMounted={this.handleMapMounted}
          markers={markers}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          onFavoriteToggle={this.onFavoriteToggle}
          onDiscard={this.discardProperty}
        />
        {this.state.initial_search ? null :
          <div className="container-fluid">
            <div className="row mt-4 search-results">
              <div className="col-md-3 search-filters">

                <h3>{this.I18n.t('filters.title')}</h3>
                {this.state.showFavorites ?
                  <div>{this.I18n.t('filters.favorites_shown')}</div> :
                  <div>
                    <FiltersAll filter={this.state.filter}
                      properties={properties}
                      per_building_type={this.state.per_building_type}
                      onChange={this.onFiltersChange} />
                    <ByBuildingType filter={this.state.filter}
                      onChange={this.onFiltersChange}
                      properties={this.state.properties}
                    />
                    <div className="form-group">
                      <a href="#" onClick={this.search} className="btn btn-primary btn-block btn-lg">
                        {this.I18n.t('filters.filter_btn')}
                      </a>
                    </div>
                  </div>}

                {this.state.discarded.length > 0 ?
                  <div className="form-group cancel-discard">
                    <a href="#" onClick={this.cancelDiscarded} className="btn btn-warning btn-block btn-sm">
                      <Icon name="ion-trash-b mr-2" />
                      {this.I18n.t('actions.cancel_discarded')}
                      <small className="label label-danger ml-2">{this.state.discarded.length}</small>
                    </a></div> : null
                }
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-5">
                    {!this.state.showFavorites ?
                      <h3>{this.I18n.t('filters.results_count')} {properties.length}</h3>
                      :
                      <h3>{this.I18n.t('filters.favorites_title')} {properties.length}</h3>
                    }
                  </div>

                  <div className="col-md-4 p-3 d-flex flex-row sort-options">
                    <label>{this.I18n.t('actions.sort')}</label>
                    <Select
                      isClearable={false}
                      isSearchable={false}
                      placeholder={this.I18n.t('actions.sort')}
                      value={this.sortOptions.find(o => o.value === this.state.filter.sort)}
                      options={this.sortOptions}
                      onChange={this.handleSortChange}
                    />
                  </div>
                  <div className="col-md-3 p-3">
                    <a href="#"
                      className="btn btn-secondary"
                      onClick={this.toggleFavorites}>
                      {!this.state.showFavorites ?
                        <span>
                          <Icon name="ion-android-favorite color-habitat mr-2" />
                          {this.I18n.t('actions.show_favorites')}
                          &nbsp;({favorites.length})
                        </span>
                        :
                        <span>
                          <Icon name="ion-android-favorite-outline" /> {this.I18n.t('actions.hide_favorites')}
                        </span>
                      }
                    </a>
                  </div>
                </div>

                <div className="d-flex flex-wrap">
                  {properties.map((property, index) =>
                    <div
                      className="d-flex mr-2 search-result-item"
                      key={`property-${property.property_id}`}
                    >
                      <PropertyView property={property}
                        onFavoriteToggle={this.onFavoriteToggle}
                        onDiscard={this.discardProperty}
                        onPropertyClick={this.onPropertyClick}
                      />
                    </div>
                  )}
                </div>
		
		<div className="d-flex justify-content-center" style={{justifyContent: 'center'}}>
		{
		  this.state.page < this.state.total_pages ?
		 	<button className="btn btn-primary btn-lg"  onClick={() =>this.search(null, false, this.state.page+1)}>
		   	    Load More
		 	</button>
		  :
		  ''
		 }
		</div>
              </div>
            </div>
          </div>}
      </div>
    </div>;
  }
}

MainSearch.defaultProps = {
  center: { lat: 44.8129862, lng: 20.468124 },
  zoom: 14,
  search_url: '/en/search'
};

MainSearch.propTypes = {
  per_building_type: PropTypes.object.isRequired,
  bounding_box: PropTypes.object,
  zoom: PropTypes.number,
  search_url: PropTypes.string
};

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('main-map')) {
    const url = document.getElementById('main-map').getAttribute('data-url');
    const per_building_type = JSON.parse(document.getElementById('main-map').getAttribute('data-per-building-type')) || {};
    ReactDOM.render(
      <MainSearch search_url={url} per_building_type={per_building_type} />,
      document.getElementById('main-map'),
    )
  }
});
