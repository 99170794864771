import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import getAverageColourAsRGB from '../utils/average-color';
class PropertyGallery extends Component {
  constructor(props) {
    super(props);

    this.image_refs = [];
  }

  componentDidMount() {
    if (this.image_refs.length > 0) {
      setTimeout(() => {
        const rgb = getAverageColourAsRGB(this.image_refs[0]);
        if (this.props.onAverageColor) {
          this.props.onAverageColor(rgb);
        }
      }, 300);
    }
  }

  render() {
    const {property, size, dots} = this.props;
    if (property.images.length === 0) return null;

    var settings = {
      dots: dots,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      swipeToSlide: false,
      touchMove: false,
      lazyLoad: false
    };

    const slides = property.images.map((image, index) => {
      return <div key={`slide-${index}`}>
        <img src={image[size]} width={320} ref={(el) => {
          this.image_refs[index] = el
        }}/>
      </div>
    });

    return <Slider {...settings}>
      {slides}
    </Slider>
  }
}

PropertyGallery.defaultProps = {
  size: 'small',
  dots: false
};

PropertyGallery.propTypes = {
  property: PropTypes.object.isRequired,
  size: PropTypes.string,
  dots: PropTypes.bool,
  onAverageColor: PropTypes.func
};

export default PropertyGallery;