import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropertyGallery from './property-gallery';
import guid from '../utils/guid';
import { I18n } from '../locales/index';
import Icon from 'packs/utils/icon';
import cn from 'classnames'

require('jquery')
require('bootstrap')
class PropertyPopup extends Component {
  constructor(props) {
    super(props);

    this.onPropertyClick = this.onPropertyClick.bind(this);
    this.onAverageColor = this.onAverageColor.bind(this);

    this.itemElement = 'random-' + guid();

    this.I18n = new I18n();

    this.state = {
      favorite: this.props.property.isFavorited(),
      idle: false
    };

    this.addToFavorites = this.addToFavorites.bind(this);
    this.removeFromFavorites = this.removeFromFavorites.bind(this);
    this.discard = this.discard.bind(this);
  }

  discard(e) {
    e.preventDefault();

    this.props.onDiscard(this.props.property);
  }

  addToFavorites(e) {
    if (e) e.preventDefault();

    if (this.props.property) {
      this.props.property.addToFavorites();
      this.setState({ favorite: true }, () => {
        this.props.onFavoriteToggle(this.props.property.property_id, true)
      });
    }
  }

  removeFromFavorites(e) {
    if (e) e.preventDefault();

    if (this.props.property) {
      this.props.property.removeFromFavorites();
      this.setState({ favorite: false }, () => {
        this.props.onFavoriteToggle(this.props.property.property_id, false)
      });
    }
  }

  onPropertyClick(e) {
    if (e) e.preventDefault();

    this.props.onPropertyClick(this.props.property);
  }

  onAverageColor(color) {
    if (color) {
      let colorString = `rgb(${color.r}, ${color.g}, ${color.b})`;
      if (colorString != 'rgb(0, 0, 0)') {
        if (this.itemElement) {
          $(`#${this.itemElement}`).find('.average-color').css({ backgroundColor: colorString });
        }
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ idle: true });
    }, 100)

    $(this.discardBtn).tooltip()
  }

  render() {
    const { property } = this.props;

    return <div className="fade-in-marker">
      <div
        className="infobox" id={this.itemElement}>
        <div className="left">
          <div className="image">
            <div className="price average-color"><span>{property.price}</span></div>
            <PropertyGallery property={property} size="large" onAverageColor={this.onAverageColor} />
            <div className="discard-property">
              <a href="#"
                ref={el => this.discardBtn = el}
                className="discard-btn"
                title={this.I18n.t('actions.discard')}
                onClick={this.discard}>
                <Icon name="ion-trash-b" />
              </a>
            </div>
          </div>
          <div className="favorite">
            {property.favorited ?
              <a href="#"
                className="favorites-btn remove"
                onClick={this.removeFromFavorites}
              ><Icon name="ion-android-favorite" /></a> :
              <a href="#"
                className="favorites-btn add"
                onClick={this.addToFavorites}
              ><Icon name="ion-android-favorite-outline" /></a>}
          </div>

          <a href="#" onClick={this.onPropertyClick}>
            <header className="average-color">
              <h1 className={cn({
                animate: true,
                move_from_top_short: true,
                idle: this.state.idle
              })}>{property.street_name} </h1>
              <h2 className={cn({
                animate: true,
                move_from_top_short: true,
                idle: this.state.idle
              })}>
                {this.I18n.t(`property.city_areas.${property.city_area}`)}
              </h2>
            </header>
          </a>
        </div>
        <div className="right">
          <article className="description">
            <h3>{this.I18n.t('property.description')}</h3>
            <p>{property.description} </p>
          </article>
          <article className={cn({ animate: true, move_from_top_short: true, idle: this.state.idle })}>
            <h3>{this.I18n.t('property.overview')}</h3>
            <dl>
              <dt>{this.I18n.t('property.square_area')}</dt>
              {property.square_areas.map((area, index) => (
                <dd key={index}>{area.area}m<sup>2</sup></dd>
              ))}
              <dt>
                {property.isOffice() && (this.I18n.t('property.offices'))}
                {property.isResidential() && (this.I18n.t('property.bedrooms'))}
                {property.isIndustrialOrRetails() && this.I18n.t('property.rooms')}
              </dt>
              {property.isResidential() && (<dd>{property.bedrooms}</dd>)}
              {!property.isResidential() && (<dd>{property.rooms}</dd>)}
              <dt>{this.I18n.t('property.bathrooms')}</dt>
              <dd>{property.bathrooms}</dd>
              <dt>{this.I18n.t('property.garage')}</dt>
              <dd>{this.I18n.t(`property.boolean_values.${property.garage}`)}</dd>
            </dl>
          </article>
        </div>
      </div>
    </div>
  }
}

PropertyPopup.propTypes = {
  property: PropTypes.object.isRequired,
  onPropertyClick: PropTypes.func.isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired
};

export default PropertyPopup;