import BaseModel from './base';
import Cookies from 'js-cookie';

class Property extends BaseModel {
  constructor(json) {
    super();
    this.fields = ['property_id', 'status', 'street_name', 'building_type', 'property_type', 'square_area', 'price', 'price_integer',
      'floor', 'floors', 'storey', 'elevator', 'garage', 'garage_notes', 'parking', 'parking_notes', 'rooms', 'bedrooms',
      'bathrooms', 'bathroom_type', 'bathroom_notes', 'furnishment', 'style_of_furnish', 'furnishment_at_rent', 'kitchen_type',
      'terrace', 'terrace_notes', 'heating', 'heating_notes', 'phone_lines', 'description', 'video', 'available', 'condition',
      'rating', 'construction_year', 'yard', 'yard_notes', 'appliances_notes', 'available_appliances', 'building_security', 'toilets', 'terraces',
      'porter', 'swimming_pool', 'location', 'images', 'distribution', 'city_area', 'floor_type', 'square_areas', 'prices', 'per_m2'];

    // this.with_setters = ['garage'];
    this.fromJson(json);

    this.showInfo = false;
    this.favorited = this.isFavorited();
  }

  // set_garage(value) {
  //   this.garage = value ? 'yes' : 'no';
  // }

  url() {
    return '/property/' + this.property_id;
  }

  getImage(size = 'small') {
    if (this.images.length === 0) return null;

    return this.images[0][size];
  }

  isOffice() {
    return this.building_type === 'office';
  }

  isResidential() {
    return this.building_type === 'residential';
  }

  isIndustrialOrRetails() {
    return this.building_type === 'retail' || this.building_type === 'industrial';
  }

  addToFavorites() {
    const favorites = Cookies.getJSON('favorites') || [];
    favorites.push(this.property_id);
    if (favorites.length > 30) {
      favorites.splice(0, 1);
    }
    Cookies.set('favorites', favorites);
  }

  removeFromFavorites() {
    const favorites = Cookies.getJSON('favorites') || [];
    const index = favorites.indexOf(this.property_id);
    if (index > -1) {
      favorites.splice(index, 1);
    }
    Cookies.set('favorites', favorites);
  }

  isFavorited() {
    const favorites = Cookies.get('favorites') || [];
    return favorites.indexOf(this.property_id) > -1;
  }
}

export default Property;