import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';

import Select from 'react-select'

class Office extends Base {
  constructor(props) {
    super(props);

    this.handleDistributionChange = this.handleDistributionChange.bind(this);
    this.handleFloorTypeChange = this.handleFloorTypeChange.bind(this);
  }

  handleDistributionChange(distribution) {
    const {filter} = this.state;
    const _selected = filter.distribution ? filter.distribution.split(',').length : 0;
    filter.distribution = distribution.map(pt => pt.value).join(',');
    this.setState({filter: filter}, () => {
      const new_selected = filter.distribution ? filter.distribution.split(',').length : 0;
      const search_if_removed = _selected > new_selected;
      this.props.onChange(this.state.filter, search_if_removed);
    });
  }

  handleFloorTypeChange(floor_type) {
    const {filter} = this.state;
    const floor_type_selected = filter.floor_type ? filter.floor_type.split(',').length : 0;
    filter.floor_type = floor_type.map(pt => pt.value).join(',');
    this.setState({filter: filter}, () => {
      const new_floor_type_selected = filter.floor_type ? filter.floor_type.split(',').length : 0;
      const search_if_removed = floor_type_selected > new_floor_type_selected;
      this.props.onChange(this.state.filter, search_if_removed);
    });
  }

  render() {
    return <div>
      <div className="form-group">
        <label>{this.I18n.t('filters.distribution')}</label>
        <Select
          isMulti
          isClearable={false}
          isSearchable={false}
          placeholder={this.I18n.t('filters.distribution')}
          value={this.distributionTypes.find(o => o.value === this.state.filter.distribution)}
          options={this.distributionTypes}
          onChange={this.handleDistributionChange}
        />
      </div>
      <div className="form-group">
        <label>{this.I18n.t('filters.floor_type')}</label>
        <Select
          isMulti
          isClearable={false}
          isSearchable={false}
          placeholder={this.I18n.t('filters.floor_type')}
          value={this.floorTypes.find(o => o.value === this.state.filter.floor_type)}
          options={this.floorTypes}
          onChange={this.handleFloorTypeChange}
        />
      </div>

      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="elevator" checked={this.state.filter.elevator} onChange={this.handleChange}/>
          {this.I18n.t('filters.elevator')}
          &nbsp;({this.state.properties.filter(p => p.elevator === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="garage" checked={this.state.filter.garage} onChange={this.handleChange}/>
          {this.I18n.t('filters.garage')}
          &nbsp;({this.state.properties.filter(p => p.garage === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="parking" checked={this.state.filter.parking} onChange={this.handleChange}/>
          {this.I18n.t('filters.parking')}
          &nbsp;({this.state.properties.filter(p => p.parking === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="terrace" checked={this.state.filter.terrace} onChange={this.handleChange}/>
          {this.I18n.t('filters.terrace')}
          &nbsp;({this.state.properties.filter(p => p.terrace === true).length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="doorkeeper" checked={this.state.filter.doorkeeper} onChange={this.handleChange}/>
          {this.I18n.t('filters.doorkeeper')}
          &nbsp;({this.state.properties.filter(p => p.porter === 'yes').length})
        </label>
      </div>
      <div className="form-group checkbox">
        <label>
          <input type="checkbox" name="security" checked={this.state.filter.security} onChange={this.handleChange}/>
          {this.I18n.t('filters.security')}
          &nbsp;({this.state.properties.filter(p => p.building_security === true).length})
        </label>
      </div>
    </div>
  }
}
Office.propTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired
};

export default Office;