import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import 'whatwg-fetch';

import Icon from 'packs/utils/icon';
import PropertyGallery from './property-gallery';
import { I18n } from 'packs/locales/index';
import Contact from './contact';
import guid from 'packs/utils/guid';

import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import mapStyles from '../utils/map-style.json';

const SimpleGoogleMap = withGoogleMap(props => (
  <GoogleMap
    zoom={props.zoom}
    defaultOptions={{
      styles: mapStyles,
      disableDefaultUI: false,
      scrollwheel: false,
      streetViewControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER
      },
      panControl: false,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    }}
    center={props.center}>
    <Marker
      icon={{ url: '/img/markerred.png', scaledSize: new google.maps.Size(28, 33) }}
      position={new google.maps.LatLng(props.marker.latitude, props.marker.longitude)}
      draggable={false}
    />
  </GoogleMap>
));

class PropertyPage extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.I18n = new I18n();
    this.itemElement = 'random-' + guid();
    this.onAverageColor = this.onAverageColor.bind(this);

    this.shareUrlRef = null;

    this.state = {
      idle: false,
      url: null,
    }
  }

  close(e) {
    if (e) e.preventDefault();

    this.props.onPropertyClose()
    this.setState({ idle: false })
  }

  componentDidUpdate() {
    if (this.props.property) {
      $('#google-map-wrapper').addClass('offset-col-9 idle');
      $('body').addClass('modal-open');
      $("#google-map-wrapper").css('pointer-events', 'none');
    } else {
      $('#google-map-wrapper').removeClass('offset-col-9 idle');
      $('body').removeClass('modal-open');
      $("#google-map-wrapper").css('pointer-events', 'all');
    }
  }

  onAverageColor(color) {
    if (color) {
      let colorString = `rgb(${color.r}, ${color.g}, ${color.b})`;
      if (colorString != 'rgb(0, 0, 0)') {
        if (this.itemElement) {
          $(`#${this.itemElement}`).find('.average-color').css({ backgroundColor: colorString });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.property) {
      setTimeout(() => {
        this.setState({ idle: true })
      }, 50);
      const shouldLoadTinyUrl = !this.props.property || (this.props.property && this.props.property.id !== nextProps.property.id);
      if (shouldLoadTinyUrl) {
        const token = $('meta[name="csrf-token"]').attr('content');

        const url = nextProps.composeSearchUrl;

        fetch('/tinyurl', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            url2short: `#${url}`,
          }),
        }).then((response) => response.json())
          .then((json) => {
            this.setState({ url: json.url })
          });
      }
    }
  }

  render() {
    const { property } = this.props;
    if (!property) return null;
    const center = { lat: property.location[0], lng: property.location[1] };

    return <div className="content-inner" id={this.itemElement}>
      <div className="container" id="main-container">
        <div className="content-loader">
          <div className={cn({
            content: true,
            fade_in: true,
            animate: true,
            idle: this.state.idle
          })}>
            <a href="#" className="close" onClick={this.close}>
              <img src="/img/close.png" />
            </a>
            <div id="item-detail">
              <div className="">
                <div className="col-md-8">
                  <div className="inner">
                    <article className={cn({
                      animate: true,
                      move_from_bottom_short: true,
                      idle: this.state.idle
                    })}>
                      <div className="gallery">
                        <div className="image">
                          <PropertyGallery property={property}
                            size="large"
                            onAverageColor={this.onAverageColor}
                            dots />
                        </div>
                      </div>
                    </article>
                    <article className={cn({
                      animate: true,
                      move_from_bottom_short: true,
                      idle: this.state.idle
                    })}>
                      <h1>{property.street_name}</h1>
                      <h2>
                        <Icon name="fa fa-map-marker" /> {this.I18n.t(`property.city_areas.${property.city_area}`)}
                      </h2>
                      <h6>
                        {this.I18n.t('property.property_id')}: {property.property_id}
                      </h6>
                      <figure className="price average-color">
                        <span>
                          {`${property.price} ${property.per_m2 ? this.I18n.t(`property.per_m2`) : ''}`}
                        </span>
                      </figure>
                      <p>
                        {property.description}
                      </p>
                    </article>

                    <article className="sidebar">
                      <div className={cn({
                        animate: true,
                        move_from_bottom_short: true,
                        idle: this.state.idle
                      })}>
                        <Contact property={property} />
                      </div>

                      <div className={cn({
                        animate: true,
                        block: true,
                        move_from_bottom_short: true,
                        idle: this.state.idle,
                      })}
                      >
                        <dl className="short-url">
                          <input
                              type="text"
                              ref={(el) => this.shareUrlRef = el}
                                 className="form-input share-url-input"
                              readOnly
                              value={this.state.url || ''}
                              onClick={() => {
                                  if (this.shareUrlRef) {
                                      this.shareUrlRef.select()
                                  }
                              }}
                          />
                        </dl>
                      </div>

                      <div className={cn({
                        animate: true,
                        block: true,
                        move_from_bottom_short: true,
                        idle: this.state.idle
                      })}>
                        <dl>
                          <dt>{this.I18n.t('property.square_area')}</dt>
                          {property.square_areas.map((area, index) => (
                            <dd key={index}>{area.area}m<sup>2</sup></dd>
                          ))}

                          <dt>
                            {property.isOffice() && (this.I18n.t('property.offices'))}
                            {property.isResidential() && (this.I18n.t('property.bedrooms'))}
                            {property.isIndustrialOrRetails() && this.I18n.t('property.rooms')}
                          </dt>
                          {property.isResidential() && (<dd>{property.bedrooms}</dd>)}
                          {!property.isResidential() && (<dd>{property.rooms}</dd>)}
                          <dt>{this.I18n.t('property.bathrooms')}</dt>
                          <dd>{property.bathrooms}</dd>
                          <dt>{this.I18n.t('property.toilets')}</dt>
                          <dd>{property.toilets}</dd>
                          {property.isOffice() ?
                            <div>
                              <dt>{this.I18n.t('filters.distribution')}</dt>
                              <dd>{this.I18n.t(`filters.distribution_types.${property.distribution}`)}</dd>
                            </div>
                            : null
                          }
                        </dl>
                      </div>

                      <div className={cn({
                        animate: true,
                        block: true,
                        move_from_bottom_short: true,
                        idle: this.state.idle
                      })}>
                        <dl>
                          <dt>{this.I18n.t('property.garage')}</dt>
                          <dd>{this.I18n.t(`property.boolean_values.${property.garage}`)}</dd>
                          <dt>{this.I18n.t('property.parking')}</dt>
                          <dd>{this.I18n.t(`property.boolean_values.${property.parking}`)}</dd>
                          <dt>{this.I18n.t('property.terrace')}</dt>
                          <dd>{property.terraces}</dd>
                          <dt>{this.I18n.t('property.elevator')}</dt>
                          <dd>{this.I18n.t(`property.boolean_values.${property.elevator}`)}</dd>
                          <dt>{this.I18n.t('property.yard')}</dt>
                          <dd>{this.I18n.t(`property.boolean_values.${property.yard}`)}</dd>
                        </dl>
                      </div>
                    </article>

                    <article className={cn({
                      animate: true,
                      move_from_bottom_short: true,
                      idle: this.state.idle
                    })}>
                      <h3>{this.I18n.t('property.features')}</h3>
                      <ul className="bullets">
                        {Object.keys(property.available_appliances).map((appliance, index) => {
                          const obj = property.available_appliances[appliance];

                          return <li key={`appliance-${index}`}>
                            {appliance}
                            {obj.value != 'yes' ? <small className="ml-3 text-muted">{obj.label}</small> : null}
                          </li>
                        }
                        )}
                      </ul>
                    </article>

                    <article>
                      <h3>{this.I18n.t('property.map')}</h3>
                      <div id="map-simple" className={cn({ idle: true })}>
                        <SimpleGoogleMap
                          zoom={this.props.zoom}
                          center={center}
                          containerElement={
                            <div style={{ height: `100%` }} />
                          }
                          mapElement={
                            <div style={{ height: `350px` }} />
                          }
                          marker={{ latitude: center.lat, longitude: center.lng }}
                        />
                      </div>
                    </article>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  }
}

PropertyPage.defaultProps = {
  zoom: 16
};

PropertyPage.propTypes = {
  onPropertyClose: PropTypes.func.isRequired,
  property: PropTypes.object,
  zoom: PropTypes.number
};

export default PropertyPage;
