class Base {
  constructor() {
    this.fields = [];
    this.with_setters = [];
  }
  fromJson(json) {
    Object.keys(json).forEach(key => {
      if(this.fields.indexOf(key) > -1) {
        this[key] = json[key];
      } else if(this.with_setters.indexOf(key) > -1) {
        this[`set_${key}`](json[key]);
      }
    });
    return this;
  }
}

export default Base;