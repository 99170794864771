import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Icon extends Component {
  render() {
    const {name} = this.props;
    if(!name) return null;

    return <i className={name}></i>
  }
}

Icon.defaultProps = {
  name: null
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;