import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Residential from './residential';
import Office from './office';

class ByBuildingType extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let view = null;
    switch (this.props.filter.building_type) {
      case 'residential':
        view = <Residential {...this.props}/>;
        break;
      case 'office':
        view = <Office {...this.props}/>;
      default:
        break;
    }
    return <div>
      {view}
    </div>
  }
}

ByBuildingType.propTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired
};

export default ByBuildingType;