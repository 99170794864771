export default (new_hash) => {
  if ('replaceState' in history) { // Yay, supported!
    let href = window.location.href.split('#')[0];
    if (new_hash) {
      if (('' + new_hash).charAt(0) !== '#') {
        href += '#' + new_hash;
      } else {
        href += new_hash;
      }
    }
    history.replaceState('', '', href);
  }
}