import sr from './sr';
import en from './en';
import _ from 'lodash';

const locales= {
  en: en,
  sr: sr
};
class I18n {
  constructor() {
    const lang = document.body.lang;
    this.locale = lang || 'en';
  };

  t(key) {
    return _.get(locales[this.locale], key, '');
  }

}
export {I18n};