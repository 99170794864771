export default {
  primary_search: {
    title: 'Find your home',
    price_min: 'Price min',
    price_max: 'Price max',
    area_min: 'Size min',
    area_max: 'Size max',
    building_type_placeholder: 'What Type of Building?',
    property_type_placeholder: 'What Type of Property?',
    rent_type: {
      rent: 'Rent',
      sale: 'Sale',
    },
    find: 'Search'
  },
  actions: {
    show_favorites: 'Show Favorites',
    hide_favorites: 'Show Search Results',
    sort: 'Sort by',
    sort_types: {
      rating: 'Our Rating',
      price: 'Price Ascending',
      area: 'Size Ascending'
    },
    cancel_discarded: 'Bring back discarded',
    discard: 'Remove from search results',
    remove_image: 'Remove',
    mark_as_preview: 'Mark as preview'
  },
  confirmations: {
    image_remove: 'Are you sure you want to remove image?'
  },
  alerts: {
    image_remove_error: 'There was a problem removing an image',
    image_preview_error: 'There was a problem marking image as preview'
  },
  images: {
    preview: 'Preview Image'
  },
  filters: {
    favorites_title: 'Favorites:',
    favorites_shown: 'You can not search through favorites',
    title: 'Refine your search',
    building_type: 'Building type',
    property_id: 'Search by Property ID',
    property_id_placeholder: 'Enter Property ID',
    price_min: 'Price min',
    price_max: 'Price max',
    area_min: 'Size min',
    area_max: 'Size max',
    filter_btn: 'Filter',
    results_count: 'Properties matching criteria:',
    bedrooms: 'Bedrooms',
    bedroom_options: {
      '1': '1+',
      '2': '2+',
      '3': '3+',
      '4': '4+',
      '5': 'five or more'
    },
    bathrooms: 'Bathrooms',
    bathroom_options: {
      '1': '1+',
      '2': '2+',
      '3': '3+',
      '4': '4+',
      '5': 'five or more'
    },
    furnishment: 'Furnishment',
    furnishment_types: {
      furnished: 'Furnished',
      unfurnished: 'Unfurnished',
      partially_furnished: 'Partially furnished',
      tba: 'To Be Agreed'
    },
    floor_type: 'Floor type',
    floor_types: {
      ground: 'Ground Floor',
      mezzanine: 'Mezzanine (1st floor)',
      middle: 'Middle Floors',
      top: 'Top Floors'
    },
    elevator: 'Elevator',
    garage: 'Garage',
    parking: 'Parking',
    terrace: 'Terrace',
    yard: 'Yard',
    swimming_pool: 'Swimming pool',
    doorkeeper: 'Doorkeeper',
    security: 'Security',
    distribution: 'Distribution',
    distribution_types: {
      open_plan: 'Open plan',
      walled: 'Walled',
      indifferent: 'Indifferent'
    }
  },
  emails: {
    residential: 'residential@habitat.rs',
    office: 'office@habitat.rs',
    retail: 'retail@habitat.rs',
    industrial: 'industrial@habitat.rs'
  },
  property: {
    property_id: 'Property ID',
    street_name: 'Street',
    url: 'Share URL',
    city_areas: {
      banovo_brdo: 'Banovo Brdo',
      center: 'Center',
      dedinje: 'Dedinje',
      kosutnjak: 'Kosutnjak',
      new_belgrade: 'New Belgrade',
      senjak: 'Senjak',
      vozdovac: 'Vozdovac',
      vracar: 'Vracar',
      zemun: 'Zemun',
      zvezdara: 'Zvezdara'
    },
    building_type: 'Building Type',
    building_types: {
      residential: 'Residential',
      office: 'Office',
      retail: 'Retail',
      industrial: 'Industrial and Warehouse'
    },
    property_type: 'Property Type',
    property_types: {
      apartment: 'Apartment',
      penthouse: 'Penthouse',
      duplex: 'Duplex',
      house_single: 'House',
      house_semi_detached: 'Semi-Detached House',
      house_in_row: 'House In Row',
      office_building: 'Office Building',
      mixed_use_apartment: 'Mixed-Use',
      shopping_center: 'Shopping Center',
      high_street: 'High Street',
      other: 'Other',
      warehouse: 'Warehouse',
      industrial_premises: 'Industrial Premises'
    },
    square_area: 'Size',
    price: 'Price',
    per_m2: '/ m2',
    floor: 'Floor',
    floors: 'Number of Floors',
    floor_type: 'Floor type',
    floor_types: {
      ground: 'Ground Floor',
      mezzanine: 'Mezzanine (1st floor)',
      middle: 'Middle Floors',
      top: 'Top Floors'
    },
    storey: 'Storey',
    elevator: 'Elevator',
    garage: 'Garage',
    garage_notes: 'Garage Notes',
    parking: 'Parking',
    parking_notes: 'Parking Notes',
    rooms: 'Rooms',
    bedrooms: 'Bedrooms',
    offices: 'Offices',
    bathrooms: 'Bathrooms',
    toilets: 'Toilets',
    bathroom_type: 'Bathroom Type',
    bathroom_types: {
      full: 'Full Bathroom',
      half: 'Half Bathroom',
      toilet: 'Toilet',
      laundry: 'Laundry'
    },
    bathroom_notes: 'Bathroom Notes',
    furnishment: 'Furnishment',
    furnishment_types: {
      furnished: 'Furnished',
      unfurnished: 'Unfurnished',
      partially_furnished: 'Partially Furnished'
    },
    style_of_furnish: 'Style of Furnishment',
    furnishment_style: {
      modern: 'Modern',
      classic: 'Classic',
      rustic: 'Rustic',
      vintage: 'Vintage',
      romantic: 'Romantic'
    },
    furnishment_at_rent: 'Furnishment at Rent',
    furnishment_at_rent_types: {
      furnished: 'Furnished',
      unfurnished: 'Unfurnished',
      partially_furnished: 'Partially furnished',
      tba: 'To be Agreed'
    },
    kitchen_type: 'Kitchen Type',
    kitchen_types: {
      separate: 'Separate',
      open: 'Open',
      semi_open: 'Semi Open'
    },
    terrace: 'Terrace',
    heating: 'Heating',
    heating_notes: 'Heating Notes',
    heating_types: {
      central: 'Central',
      electric: 'Electric',
      gas: 'Gas',
      oil: 'Oil',
      other: 'Other'
    },
    phone_lines: 'Phone Lines',
    description: 'Description',
    yard: 'Yard',
    yard_notes: 'Yard Notes',
    construction_year: 'Construction Year',
    condition: 'Condition',
    condition_types: {
      new: 'New',
      renovated: 'Renovated',
      partially_renovated: 'Partially Renovated',
      needs_renovation: 'Needs Renovation',
      under_construction: 'Under Construction'
    },
    rating: 'Property Rating',
    kitchen_appliances: 'Kitchen Appliances',
    dishwasher: 'Dishwasher',
    washing_machine: 'Washing Machine',
    dryer: 'Dryer',
    tv_set: 'TV set',
    air_conditioning: 'Air Conditioning',
    cable_tv: 'Cable TV',
    satellite: 'Satellite TV',
    internet: 'Internet',
    video_surveillance: 'Video Surveillance',
    alarm_system: 'Alarm System',
    swimming_pool: 'Swimming Pool',
    gym: 'Gym',
    sauna: 'Sauna',
    porter: 'Porter',
    appliance_options: {
      yes: 'Yes',
      no: 'No'
    },
    boolean_values: {
      true: 'Yes',
      false: 'No',
      yes: 'Yes',
      no: 'No'
    },
    impending: 'Impending',
    adsl: 'ADSL',
    cable: 'Cable',
    wifi: 'WiFi',
    features: 'Features',
    map: 'Map',
    overview: 'Overview'
  }
}