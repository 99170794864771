import qs from 'query-string';
class Filter {
  constructor() {
    this.building_type = '';
    this.price_min = '';
    this.price_max = '';
    this.area_min = '';
    this.area_max = '';

    this.property_id = '';
    this.property = '';

    this.bedrooms = '';
    this.bathrooms = '';

    this.furnishment = '';
    this.distribution = '';

    this.elevator = false;
    this.garage = false;
    this.parking = false;
    this.terrace = false;
    this.yard = false;
    this.swimming_pool = false;
    this.doorkeeper = false;
    this.security = false;
    this.sort = 'rating'
  }

  set(json) {
    Object.keys(json).forEach(key => {
      this[key] = json[key];
    });

    this.elevator = eval(this.elevator);
    this.garage = eval(this.garage);
    this.parking = eval(this.parking);
    this.terrace = eval(this.terrace);
    this.yard = eval(this.yard);
    this.swimming_pool = eval(this.swimming_pool);
    this.doorkeeper = eval(this.doorkeeper);
    this.security = eval(this.security);

    return this;
  }

  serialize() {
    return qs.stringify(this);
  }

  deserialize(json) {
    this.set(qs.parse(json));
  }
}

export default Filter;