import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'packs/locales';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.I18n = new I18n();
  }

  render() {
    const {property} = this.props;

    return <div className="person animate move_from_bottom_short idle">
      <div className="inner average-color">

        <header className="m-0 mb-2">
          {this.I18n.t(`property.building_types.${property.building_type}`)}
          <span className="p-2">-</span>
          {this.I18n.t(`property.property_types.${property.property_type}`)}
        </header>

        <a href={`mailto:${this.I18n.t(`emails.${property.building_type}`)}`}>
          {this.I18n.t(`emails.${property.building_type}`)}
        </a>

      </div>
    </div>
  }
}

Contact.propTypes = {
  property: PropTypes.object.isRequired
};

export default Contact;