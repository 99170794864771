import React from 'react'
import PropTypes from 'prop-types';
import Base from './base';
import Select from 'react-select';

import {getPropertyTypes, getBuildingTypes} from 'packs/utils/types';

class All extends Base {
  constructor(props) {
    super(props);

    this.buildingOptions = getBuildingTypes(this.I18n, props.per_building_type);
    this.propertyTypes = getPropertyTypes(this.I18n, props.properties);

    this.handleBuildingTypeChange = this.handleBuildingTypeChange.bind(this);
    this.handlePropertyTypeChange = this.handlePropertyTypeChange.bind(this);
    this.handleRentType = this.handleRentType.bind(this);
  }

  handleBuildingTypeChange(building_type) {
    const {filter} = this.state;
    filter.building_type = building_type.value;
    filter.property_type = null;
    this.setState({filter: filter}, () => {
      this.props.onChange(this.state.filter, true);
    });
  }

  handleRentType(rent_type) {
    const {filter} = this.state;
    filter.rent_type = rent_type.value;
    this.setState({filter: filter}, () => {
      this.props.onChange(this.state.filter, true);
    });
  }

  handlePropertyTypeChange(property_type) {
    const {filter} = this.state;
    const property_types_selected = filter.property_type ? filter.property_type.split(',').length : 0;
    filter.property_type = property_type.map(pt => pt.value).join(',');
    this.setState({filter: filter}, () => {
      const new_property_types_selected = filter.property_type ? filter.property_type.split(',').length : 0;
      const search_if_removed = property_types_selected > new_property_types_selected;
      this.props.onChange(this.state.filter, search_if_removed);
    });
  }

  render() {
    return <div>
      <div className="form-group">
        <label>{this.I18n.t('filters.property_id')}</label>
        <input type="text"
               className="form-control"
               name="property_id"
               placeholder={this.I18n.t('filters.property_id_placeholder')}
               onChange={this.handleChange}
               value={this.state.filter.property_id}
        />
      </div>
      <div className="form-group">
        <Select
          name="form-rent-type"
          isClearable={false}
          isSearchable={false}
          value={this.rentOptions.find(o => o.value === this.state.filter.rent_type) || this.rentOptions[0]}
          options={this.rentOptions}
          onChange={this.handleRentType}
        />
      </div>
      <div className="form-group">
        <label>{this.I18n.t('filters.building_type')}</label>
        <Select
          name="form-field-name"
          isClearable={false}
          isSearchable={false}
          placeholder={this.I18n.t('primary_search.building_type_placeholder')}
          value={this.buildingOptions.find(o => o.value === this.state.filter.building_type)}
          options={this.buildingOptions}
          onChange={this.handleBuildingTypeChange}
        />
      </div>
      <div className="form-group">
        <label>{this.I18n.t('filters.property_type')}</label>
        <Select
          name="form-field-name"
          isClearable={false}
          isSearchable={false}
          isMulti
          placeholder={this.I18n.t('primary_search.property_type_placeholder')}
          value={(this.propertyTypes[this.state.filter.building_type] || []).find(o => o.value === this.state.filter.property_type)}
          options={this.propertyTypes[this.state.filter.building_type]}
          onChange={this.handlePropertyTypeChange}
        />
      </div>
      <div className="form-group row">
        <div className="col-md-6 price-min">
          <label>{this.I18n.t('filters.price_min')}</label>
          <div className="input-group">
            <input type="text"
                   className="form-control"
                   onChange={this.handleChange}
                   placeholder={this.I18n.t('primary_search.price_min')}
                   value={this.state.filter.price_min}
                   name="price_min"/>
            <span className="add-on">&euro;</span>
          </div>
        </div>
        <div className="col-md-6 price-max">
          <label>{this.I18n.t('filters.price_max')}</label>
          <div className="input-group">
            <input type="text"
                   className="form-control"
                   onChange={this.handleChange}
                   placeholder={this.I18n.t('primary_search.price_max')}
                   value={this.state.filter.price_max}
                   name="price_max"/>
            <span className="add-on">&euro;</span>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-6 area-min">
          <label>{this.I18n.t('filters.area_min')}</label>
          <div className="input-group">
            <input type="text"
                   className="form-control"
                   onChange={this.handleChange}
                   placeholder={this.I18n.t('primary_search.area_min')}
                   value={this.state.filter.area_min}
                   name="area_min"/>
            <span className="add-on">m<sup>2</sup></span>
          </div>
        </div>
        <div className="col-md-6 area-max">
          <label>{this.I18n.t('filters.area_max')}</label>
          <div className="input-group">
            <input type="text"
                   className="form-control"
                   onChange={this.handleChange}
                   placeholder={this.I18n.t('primary_search.area_max')}
                   value={this.state.filter.area_max}
                   name="area_max"/>
            <span className="add-on">m<sup>2</sup></span>
          </div>
        </div>
      </div>
    </div>
  }
}

All.propTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
  per_building_type: PropTypes.object.isRequired
};

export default All;