const getPropertyTypes = (I18n, properties) => {
  const result = {};
  const types = {
    residential: ['apartment',
      'penthouse',
      'duplex',
      'house_single',
      'house_semi_detached',
      'house_in_row'],
    office: ['office_building', 'mixed_use_apartment'],
    retails: ['shopping_center', 'high_street', 'other'],
    industrial: ['warehouse', 'industrial_premises']
  };
  Object.keys(types).map(building_type => {
    const property_types = [];
    types[building_type].forEach(p => {
      const properties_with_type = properties.filter((property) => {
        return property.property_type === p
      }).length;
      if (properties_with_type > 0) {
        property_types.push({
          value: p,
          label: `${I18n.t(`property.property_types.${p}`)} (${properties_with_type})`
        });
      }
    });
    result[building_type] = property_types;
  });

  return result;
};

const getFurnishmentTypes = (I18n, properties) => {
  const result = [];
  const types = ['furnished',
    'unfurnished',
    'partially_furnished',
    'tba'];

  types.forEach(p => {
    const properties_with_type = properties.filter((property) => {
      return property.furnishment_at_rent === p
    }).length;
    if (properties_with_type > 0) {
      result.push({
        value: p,
        label: `${I18n.t(`property.furnishment_types.${p}`)} (${properties_with_type})`
      });
    }
  });

  return result;
};

const getBuildingTypes = (I18n, per_building_type) => {
  return [
    {
      value: 'residential',
      label: `${I18n.t('property.building_types.residential')} (${parseInt(per_building_type.residential) || 0})`
    },
    {
      value: 'office',
      label: `${I18n.t('property.building_types.office')} (${parseInt(per_building_type.office) || 0})`
    },
    {
      value: 'retail',
      label: `${I18n.t('property.building_types.retail')} (${parseInt(per_building_type.retail) || 0})`
    },
    {
      value: 'industrial',
      label: `${I18n.t('property.building_types.industrial')} (${parseInt(per_building_type.industrial) || 0})`
    }
  ];
};

const getRoomsTypes = (I18n, properties, room_type) => {
  const result = [];
  const rooms = [1, 2, 3, 4, 5];

  rooms.forEach(count => {
    const properties_with_count = properties.filter((property) => {
      return parseInt(property.bedrooms) >= count
    }).length;
    if (properties_with_count > 0) {
      result.push({
        value: count, label: `${I18n.t(`filters.${room_type}_options.${count}`)} (${properties_with_count})`
      });
    }
  });

  return result;
};

const getDistributionTypes = (I18n, properties) => {
  const result = [];
  const types = ['open_plan',
    'walled'
  ];

  types.forEach(p => {
    const properties_with_type = properties.filter((property) => {
      return property.distribution === p
    }).length;
    if (properties_with_type > 0) {
      result.push({
        value: p,
        label: `${I18n.t(`filters.distribution_types.${p}`)} (${properties_with_type})`
      });
    }
  });

  return result;
};

const getFloorTypes = (I18n, properties) => {
  const result = [];
  const types = ['ground',
    'mezzanine',
    'middle',
    'top'
  ];

  types.forEach(p => {
    const properties_with_type = properties.filter((property) => {
      return property.floor_type === p
    }).length;
    if (properties_with_type > 0) {
      result.push({
        value: p,
        label: `${I18n.t(`filters.floor_types.${p}`)} (${properties_with_type})`
      });
    }
  });

  return result;
};

export {
  getPropertyTypes,
  getBuildingTypes,
  getRoomsTypes,
  getFurnishmentTypes,
  getDistributionTypes,
  getFloorTypes
};