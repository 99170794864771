import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {I18n} from 'packs/locales/';
import {getBuildingTypes} from 'packs/utils/types';

class PrimarySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      building_type: '',
      price_min: '',
      price_max: '',
      area_min: '',
      area_max: ''
    };

    this.I18n = new I18n();

    this.buildingOptions = getBuildingTypes(this.I18n, props.per_building_type);

    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBuildingTypeChange = this.handleBuildingTypeChange.bind(this);
  }

  search(e) {
    e.preventDefault();

    const {
      building_type,
      price_min,
      price_max,
      area_min,
      area_max
    } = this.state;

    this.props.onSearch({
      building_type: building_type,
      price_min: price_min,
      price_max: price_max,
      area_min: area_min,
      area_max: area_max
    })
  }

  handleBuildingTypeChange(building_type) {
    this.setState({building_type: building_type.value});
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  render() {
    return <div className="search-overlay idle">
      <div className="wrapper">
        <div className="inner animate move_from_bottom_short idle">
          <div className="logo">
            <img src="/logo.png"/>
          </div>
          <h2 className="text-center mb-3">{this.I18n.t('primary_search.title')}</h2>

          <div className="form-group">
            <Select
              name="form-field-name"
              isClearable={false}
              isSearchable={false}
              placeholder={this.I18n.t('primary_search.building_type_placeholder')}
              value={this.buildingOptions.find(o => o.value === this.state.building_type)}
              options={this.buildingOptions}
              onChange={this.handleBuildingTypeChange}
            />
          </div>

          <div className="form-group row">
            <div className="col-md-6 price-min">
              <div className="input-group">
                <input type="text"
                       className="form-control"
                       onChange={this.handleChange}
                       placeholder={this.I18n.t('primary_search.price_min')}
                       name="price_min"/>
                <span className="add-on">&euro;</span>
              </div>
            </div>
            <div className="col-md-6 price-max">
              <div className="input-group">
                <input type="text"
                       className="form-control"
                       onChange={this.handleChange}
                       placeholder={this.I18n.t('primary_search.price_max')}
                       name="price_max"/>
                <span className="add-on">&euro;</span>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6 price-min">
              <div className="input-group">
                <input type="text"
                       className="form-control"
                       onChange={this.handleChange}
                       placeholder={this.I18n.t('primary_search.area_min')}
                       name="area_min"/>
                <span className="add-on">m<sup>2</sup></span>
              </div>
            </div>
            <div className="col-md-6 price-max">
              <div className="input-group">
                <input type="text"
                       className="form-control"
                       onChange={this.handleChange}
                       placeholder={this.I18n.t('primary_search.area_max')}
                       name="area_max"/>
                <span className="add-on">m<sup>2</sup></span>
              </div>
            </div>
          </div>

          <div className="form-group mt-4 text-center">
            <button type="submit" className="btn mt-4 btn-primary btn-lg"
                    onClick={this.search}
            >{this.I18n.t('primary_search.find')}</button>
          </div>
        </div>
        <div className="circle">
          <div className="element"></div>
        </div>
      </div>
    </div>
  }
}

PrimarySearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  per_building_type: PropTypes.object.isRequired
};

export default PrimarySearch;